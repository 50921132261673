import { AccountStatus, ClaimInventoryViewType, UserAccessStatus, UserRole } from '@hobt/constants';

export enum UserType {
  Internal = 1,
  External = 2,
  Service = 3
}
interface AccessRole {
  activeFlag: boolean;
  roleCode?: UserRole;
}

interface Access {
  admin: AccessRole;
  arrears: AccessRole;
  claim: AccessRole & {
    inventoryViewTypeCode: ClaimInventoryViewType;
    probationaryUserFlag?: boolean;
  };
  default: AccessRole;
}

export interface UserAccessType {
  userAccessID?: string;
  userID?: string;
  allowedTransitNumbers: string[];
  defaultFinancialInstitutionCode: string;
  defaultTransitNumber: string;
  financialInstitutionCode: string;
  lenderRoleCode?: UserRole;
  access: Access;
  recordCreatedTimestamp: string;
  recordCreatedUserID: string;
  recordUpdatedTimestamp: string;
  recordUpdatedUserID: string;
  statusCode: UserAccessStatus;
}

export enum InventoryViewType {
  Initiated = 1,
  Unresticted = 2
}

export interface Status {
  textKey: string;
  color: string;
}

export enum UserAccess {
  Arrears = 1,
  Default = 2,
  Claim = 3
}

export enum UserApiResponse {
  // HTTP_ERROR_CODES
  Success,
  Created,
  BadRequest,
  Updated,
  NotAuthorized,
  Error,

  // HBT_ERROR_CODE
  DuplicateRecord,
  InconsistentEmail
}

export enum SubmissionType {
  SUBMIT = 0,
  ERROR = 1,
  OTHER = 2
}

export type ErrorObject = {
  response?: Response;
};

export type Response = {
  status?: number;
  data: DataObject;
};

export type DataObject = {
  statusCode: number;
  status?: string;
  data: any | any[];
  error: ErrorEntity;
};

export type ErrorEntity = {
  errorMessage?: string;
  errorCode?: any;
};

// TODO: Convert String to Dictionary values
export const ExternalStatus: Record<AccountStatus, Status> = {
  [AccountStatus.ACTIVE]: { textKey: 'Active', color: 'success' },
  [AccountStatus.DISABLED]: { textKey: 'Disabled', color: 'grey20' },
  [AccountStatus.DELETED]: { textKey: 'Deleted', color: 'error' },
  [AccountStatus.PENDING]: { textKey: 'Pending', color: 'warning' },
  [AccountStatus.INACTIVE]: { textKey: 'Inactive', color: 'grey20' },
  [AccountStatus.SUSPENDED]: { textKey: 'Suspended', color: 'grey20' }
};

// TODO: Convert String to Dictionary values
export const LenderRole: Record<UserRole, string> = {
  [UserRole.CmhcAdministrator]: 'Cmhc Administrator',
  [UserRole.BusinessUser]: 'Business User',
  [UserRole.Clerk]: 'Clerk',
  [UserRole.MasterUser]: 'Master User',
  [UserRole.ReadOnly]: 'Read Only',
  [UserRole.LenderAdministrator]: 'Lender Admin',
  [UserRole.ApprovedLender]: 'Approved Lender',
  [UserRole.ServiceProvider]: 'Service Provider'
};

// Map for setting toast messages
export const toastMessageMap = new Map<UserApiResponse, string>([
  [UserApiResponse.Created, 'UserManagement-AddUsers-SuccessToastMessage'],
  [UserApiResponse.Error, 'DefaultSubmission-OtherErrors'],
  [UserApiResponse.DuplicateRecord, 'UserManagement-Users-ErrorToastMessage-HBT_ERROR_4002'],
  [UserApiResponse.InconsistentEmail, 'UserManagement-Users-ErrorToastMessage-HBT_ERROR_9000']
]);

export enum LanguageTypeCode {
  English = 1,
  French = 2
}

export enum LanguageSwitchTypeCode {
  English = 'en',
  French = 'fr'
}

export enum TabType {
  Internal = 1,
  External = 2
}

export const TabMapping = new Map<TabType, string>([
  [TabType.Internal, 'internal'],
  [TabType.External, 'external']
]);
