import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

export const LoaderAnimation = () => (
  <div>
    <div className="spinning-loader--wrapper">
      <div className="spinning-loader" />
    </div>
    <div className="loading-title">
      <Text field={{ value: i18n.t('DefaultSubmission-Loading') }} />
    </div>
    <div className="loading-description">
      <Text field={{ value: i18n.t('DefaultSubmission-Loading-Details') }} />
    </div>
  </div>
);
