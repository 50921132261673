import { useEffect, useReducer } from 'react';

import {
  CustomParam,
  DataGridEngineConfig,
  DataGridState,
  DataGridActionType,
  Language
} from 'Components/Hooks/DataGridEngine/types';
import { SortDirection } from 'Components/Common/Api/CommonEnums';
import { ApiClient, ComparisonOperators, LogicalOperators } from 'Foundation/Api';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { createDataGridEngineReducer } from 'Components/Hooks/DataGridEngine/DataGridStateReducer';
import { apiClientConfig } from 'Constants/apiCallConstants';
import { useStringTemplateEngine } from 'Components/Hooks/StringTemplateEngine';
import i18n from 'i18next';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { AxiosError } from 'axios';

export const useDataGridEngine = <T extends unknown>({
  apiBaseUrl,
  onApiErrorCallback,
  defaultItemsPerPage,
  currentTab,
  captionText,
  loadDataOnInitialLoad = true,
  tabFilterQueryLookup,
  isAdmin = false,
  isUserModule = false
}: DataGridEngineConfig) => {
  const authenticationContext = useAuthenticationContext();
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const { getWithAuth } = ApiClient(authenticationContext, apiClientConfig);

  const initDataGridState: DataGridState<T> = {
    currentTab,
    pager: {
      currentPage: 1,
      itemsPerPage: defaultItemsPerPage ?? 0,
      rangeFrom: 0,
      rangeTo: 0,
      totalPages: 0,
      totalItems: 0
    },
    search: {
      field: '',
      term: undefined
    },
    sort: {
      direction: SortDirection.DEFAULT,
      fieldName: undefined
    },
    showOwnInventory: false,
    rowData: [],
    refreshData: false,
    isLoading: false
  };

  const dataGridEngineReducer = createDataGridEngineReducer<T>();
  const [gridParams, dispatch] = useReducer(dataGridEngineReducer, initDataGridState);

  // Use string replace function provided by string template engine
  const { stringReplace } = useStringTemplateEngine();

  // Caption Text
  const getTableAccessibilityText = () => {
    if (captionText != null) {
      const strReplaceMap = {
        '{numberOfResults}': gridParams.rowData?.length.toString() ?? '0',
        '{currentPage}':
          gridParams.pager.currentPage != null ? gridParams.pager.currentPage.toString() : '0',
        '{totalPages}':
          gridParams.pager.totalPages != null ? gridParams.pager.totalPages.toString() : '0',
        '{filtersApplied}': gridParams.currentTab?.displayText.value ?? ''
      };
      return stringReplace(captionText, strReplaceMap);
    }
    return '';
  };

  const composeRequestUri = (): string => {
    const userId: string | undefined = sitecoreContext?.user?.userID || undefined;

    const filterBy = [];
    if (gridParams.search.term != null && gridParams.search.field != null) {
      filterBy.push(
        `${gridParams.search.field} ${ComparisonOperators.LIKE} '%${gridParams.search.term}%'`
      );
    }
    if (gridParams.showOwnInventory && userId != null) {
      filterBy.push(`AdjudicatorUserID ${ComparisonOperators.EQ} '${userId}'`);
    }
    if (
      tabFilterQueryLookup != null &&
      currentTab?.name != null &&
      tabFilterQueryLookup[currentTab?.name] != null
    ) {
      filterBy.push(tabFilterQueryLookup[currentTab?.name]);
    }

    if (gridParams.customParams != null && typeof gridParams.customParams.body === 'string') {
      const customQuery =
        gridParams.customParams.grouped === true
          ? `( ${gridParams.customParams.body} )`
          : gridParams.customParams.body;
      filterBy.push(customQuery);
    } else if (gridParams.customParams != null) {
      filterBy.push(
        (gridParams.customParams.body as CustomParam[]).map(
          (param) => `${param.paramName} ${ComparisonOperators.EQ} '${param.paramValue}'`
        )
      );
    }

    const params: any = {
      offset: gridParams.pager.itemsPerPage * (gridParams.pager.currentPage - 1),
      limit: gridParams.pager.itemsPerPage > 0 ? gridParams.pager.itemsPerPage : undefined,
      filter_by: filterBy.length > 0 ? filterBy.join(` ${LogicalOperators.AND} `) : undefined,
      order_by:
        gridParams.sort.fieldName && gridParams.sort.direction !== SortDirection.DEFAULT
          ? `${gridParams.sort.fieldName} ${SortDirection[gridParams.sort.direction]}`
          : undefined,
      language:
        i18n.language !== undefined
          ? Language[i18n.language.toUpperCase() as unknown as Language]
          : Language.EN,
      // Conditionally add adminsOnly filter
      ...(isUserModule
        ? {
            adminsOnly: isAdmin ? 1 : 0
          }
        : {})
    };

    const queryString = Object.keys(params)
      .map((key: string) =>
        params[key] !== undefined
          ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          : null
      )
      .filter((val) => val !== null)
      .join('&');

    return `${apiBaseUrl}?${queryString}`;
  };

  const getData = async () => {
    try {
      const { data: responseData } = await getWithAuth(composeRequestUri());

      if (responseData != null) {
        const { data: rowData, page: pager } = responseData;

        dispatch({
          type: DataGridActionType.POPULATE_ROW_DATA,
          payload: { rowData, pager }
        });
      }
    } catch (e) {
      dispatch({
        type: DataGridActionType.POPULATE_ROW_DATA,
        payload: { rowData: [], initDataGridState }
      });
      onApiErrorCallback(e as AxiosError<unknown, any>);
    }
  };

  const onSetCustomFilterParams = (body: CustomParam[] | string, grouped: boolean = false) => {
    dispatch({ type: DataGridActionType.SET_CUSTOM_PARAMS, payload: { body, grouped } });
  };

  const onPagerClick = (page: number) => {
    dispatch({ type: DataGridActionType.PAGE_CHANGE, payload: page });
  };

  const onItemsPerPageChange = (updatedItemsPerPage: number | string) => {
    dispatch({
      type: DataGridActionType.ITEMS_PER_PAGE_CHANGE,
      payload: Number(updatedItemsPerPage)
    });
  };

  const onSort = (field: string) => {
    dispatch({ type: DataGridActionType.SORT_COLUMN, payload: field });
  };

  const getSortDirectionForField = (field: string) => {
    return gridParams.sort.fieldName === field ? gridParams.sort.direction : SortDirection.DEFAULT;
  };

  const onSearch = (field: string, term: string) => {
    dispatch({
      type: DataGridActionType.SEARCH,
      payload: { field, term }
    });
  };

  const onSearchReset = () => {
    dispatch({
      type: DataGridActionType.SEARCH,
      payload: {
        field: null,
        term: null
      }
    });
  };

  const onToggleShowMyInventory = () => {
    dispatch({ type: DataGridActionType.TOGGLE_SHOW_OWN_ITEMS });
  };

  useEffect(() => {
    if (currentTab !== gridParams.currentTab) {
      dispatch({ type: DataGridActionType.TAB_CHANGE, payload: currentTab });
    }
  }, [currentTab]);

  useEffect(() => {
    if (gridParams.refreshData) {
      getData();
    }
  }, [gridParams]);

  useEffect(() => {
    getData();
  }, [isAdmin]);

  useEffect(() => {
    if (loadDataOnInitialLoad === true) {
      dispatch({ type: DataGridActionType.INITIAL_LOAD });
    }
  }, []);

  return {
    rowData: gridParams.rowData,
    currentPage: gridParams.pager.currentPage,
    itemsPerPage: gridParams.pager.itemsPerPage,
    itemRangeFrom: gridParams.pager.rangeFrom,
    itemRangeTo: gridParams.pager.rangeTo,
    totalItems: gridParams.pager.totalItems,
    totalPages: gridParams.pager.totalPages,
    toggleShowMyInventory: gridParams.showOwnInventory,
    isLoading: gridParams.isLoading,
    customParams: gridParams.customParams,
    searchFieldTerm: gridParams.search,
    refresh: getData,
    onPagerClick,
    onItemsPerPageChange,
    onSort,
    getSortDirectionForField,
    onSearch,
    onSearchReset,
    onToggleShowMyInventory,
    getTableAccessibilityText,
    onSetCustomFilterParams
  };
};

export type { DataGridEngineConfig };
