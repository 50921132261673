/* eslint-disable */
import { FC, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ApplicationException, useAsyncError } from 'Foundation/Error';
import { config } from '../../config';
import { ApiClient, ApiClientConfig } from '../Api';
import {
  HbtSitecoreContextType,
  HydrateSitecoreContextProps
} from './HydrateSitecoreContext.types';

export const HydrateSitecoreContext: FC<HydrateSitecoreContextProps> = ({
  sitecoreContextFactory,
  children
}: HydrateSitecoreContextProps) => {
  const authenticationContext = useAuthenticationContext();
  const throwError = useAsyncError();

  const [loadComplete, setLoadComplete] = useState(false);

  const loadUserData = async (): Promise<void> => {
    const apiClientConfig: ApiClientConfig = {
      timeout: config.userApi.requestTimeout
    };

    try {
      const context = sitecoreContextFactory.sitecoreContext;

      // Extra check to prevent duplicate User API requests since there is a race condition that can happen that causes
      // function to be invoked several times at once before the loadComplete flag executes.
      if (loadComplete || context.user) return;

      const apiResponse: AxiosResponse = await ApiClient(
        authenticationContext,
        apiClientConfig
      ).getWithAuth(config.userApi.urls.details);

      // No user profile exists, user is not authorized.
      if (
        apiResponse.data === undefined ||
        apiResponse.data.data === undefined ||
        apiResponse.data.data.length <= 0
      ) {
        return;
      }

      const userData = apiResponse.data.data[0];

      sitecoreContextFactory.updateSitecoreContext({
        ...context,
        user: userData
      });
    } catch (error: any) {
      if (error?.response?.data?.error?.errorCode === 'HBT_ERR_7003') {
        throwError(new ApplicationException('Errors-AzureSignInFailure'));
      } else {
        throwError(new ApplicationException('Errors-UserProfileQueryFailed'));
      }
    } finally {
      setLoadComplete(true);
    }
  };

  const isUserProfileLoaded = (): boolean => {
    const sitecoreContext = sitecoreContextFactory.sitecoreContext as HbtSitecoreContextType;

    // Note that the null scenario can happen if the user logs out then back in
    return typeof sitecoreContext.user !== 'undefined' && sitecoreContext.user != null;
  };

  // Don't need to load any user context if no authentication required on route since no user to pull info for
  if (!authenticationContext.doesCurrentRouteRequireAuthentication()) {
    return children;
  }

  // Need the !loadComplete to not cause a re-render if this has already been evaluated to true previously to prevent infinite loops
  if (!loadComplete) {
    if (authenticationContext.isAuthenticated) {
      // Need to verify that the user profile was not already loaded during an earlier route render
      if (!isUserProfileLoaded()) {
        loadUserData();
      } else {
        setLoadComplete(true);
      }
    }
  }

  if (loadComplete && authenticationContext.isAuthenticated === true) {
    if (isUserProfileLoaded()) {
      return children;
    }

    console.error('No user profile was loaded.');
    throwError(new ApplicationException('Errors-NoUserProfile'));
  } else {
    console.debug('Loading user profile.');
    return null;
  }
};
