import React from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@sitecore-jss/sitecore-jss-react';

interface RoutableSitecoreLinkProps {
  link: GenericLinkItem;
  children?: React.ReactNode;
}

const RoutableSitecoreLink: React.FC<RoutableSitecoreLinkProps> = ({ link, children }) => {
  const hasValidHref = link && link.value && link.value.href;

  if (!isServer() && hasValidHref) {
    // prevent check during SSR
    const { value } = link;

    if (value.href.startsWith('/')) {
      // link is an internal route, use react-router-com
      return <RouterLink to={value.href}>{children}</RouterLink>;
    }
    return (
      // link is an external route, use full page redirects
      <Link field={link}>{children}</Link>
    );
  }
  // invalid props, do not render
  return <></>;
};

export default RoutableSitecoreLink;
