import { useFeature } from 'flagged';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RichText, Text, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { ModuleMapping, UserRole } from '@hobt/constants';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { pathNames } from 'Constants/pathNames';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import { DashboardItemProps } from './DashboardItem.types';
import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

export const DashboardItem: React.FC<DashboardItemProps> = ({
  subHeadingOne,
  subHeadingTwo,
  columnOne,
  columnTwo,
  footerTitle,
  footerContent
}) => {
  const siteTypeInternal = useFeature(FeatureFlags.INTERNAL);
  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const isMasterUser =
    isUserInRoles(
      ModuleMapping.claim,
      [UserRole.MasterUser],
      sitecoreContext?.user?.moduleRoleMapping
    ) && siteTypeInternal === true;

  const disabledLinksMap = {
    [pathNames.claim]: isMasterUser
  };

  return (
    <div className="dashboard__item--body">
      <div className="row card__body-row">
        {columnOne && (
          <div className="col-12">
            <h3 className="card__body-list-title card__body-heading-three">
              {subHeadingOne && <Text field={subHeadingOne} />}
            </h3>
            {columnOne.map((element: any, index: number) => (
              <div key={index}>
                <Image
                  field={element.fields.icon}
                  className="card-modified__body-icon material-icons icon--size-24 icon--v-align-middle icon--dark icons--v-align-middle"
                />
                {/* if admin link then open admin portal in new tab */}
                {element.id === '6509b391-9f9b-469d-a725-58222fc7f511' ? (
                  <a
                    className={`card-modified__body-list-link ${
                      disabledLinksMap[element.fields.navItem.value.href] === true
                        ? styles.disabledDashboardLink
                        : ''
                    }`.trim()}
                    href={element.fields.navItem.value.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {element.fields.navItem.value.text}
                  </a>
                ) : (
                  <RouterLink
                    to={element.fields.navItem.value.href}
                    className={`card-modified__body-list-link ${
                      disabledLinksMap[element.fields.navItem.value.href] === true
                        ? styles.disabledDashboardLink
                        : ''
                    }`.trim()}
                  >
                    {element.fields.navItem.value.text}
                  </RouterLink>
                )}
              </div>
            ))}
          </div>
        )}
        {columnTwo && (
          <div className="col-6">
            <span className="card__body-list-title card__body-heading-three">
              {subHeadingTwo && <Text field={subHeadingTwo} />}
            </span>
            {columnTwo.map((element: any, index: number) => (
              <span key={index} className="card__body-list-link">
                <Image
                  field={element.fields.icon}
                  className="card-modified__body-icon material-icons icon--size-24 icon--v-align-middle icon--dark icons--v-align-middle"
                />
                <RouterLink
                  to={element.fields.navItem.value.href}
                  className={`card-modified__body-list-link ${
                    disabledLinksMap[element.fields.navItem.value.href] === true
                      ? styles.disabledDashboardLink
                      : ''
                  }`.trim()}
                >
                  {element.fields.navItem.value.text}
                </RouterLink>
              </span>
            ))}
          </div>
        )}
      </div>
      {(footerContent || footerTitle) && (
        <div className="row card__body-row card__help-text-wrapper">
          <div className="col-12">
            <span className="card__body-text">
              {footerTitle && (
                <b>
                  <Text field={footerTitle} />
                </b>
              )}
              {footerContent && <RichText field={footerContent} />}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
