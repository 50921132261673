import React, { useState } from 'react';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { useFeature } from 'flagged';

import { LanguageShort, ModuleMapping, UserRole } from '@hobt/constants';
import { IssueToManagementStatus } from '@hobt/claim-domain';

import { PageCardContainer } from 'Components/PageComponents/PageCardContainer';
import { PageCardHeaderWithAddButton } from 'Components/PageComponents/PageCardHeaderWithAddButton';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import { MenuItemProps } from 'Components/Common/ActionMenu/types';
import { convertDateForUi } from 'Components/Common/Api/utils/DateParser';
import { DataTableFooter } from 'Components/Common/DataTableFooter';
import { AddItmFlagForm } from 'Feature/PreClaims/components/PreClaimsItmFlags/AddItmFlagForm';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { usePreclaimsFlags } from 'Feature/PreClaims/components/PreClaimsItmFlags/usePreclaimsItmFlags';
import { ApplicationStates } from 'Components/Enums/ApplicationStatus';
import { ToastNotification } from 'Feature/CommonComponents/ContentComponents';
import FormToggle from 'Components/Inputs/FormToggle';
import { Modal } from 'Components/Common/Modal';
import { useDataGridEngine } from 'Components/Hooks/DataGridEngine';
import { SearchBar } from 'Components/PageComponents/SearchBar';
import { ActionButtonCell } from 'Components/Common/DataTable/DataTableRow/ActionButtonCell';
import { ModalProps } from 'Components/Common/Modal/types';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import PreClaimsItmFlagsProps, { FlagRow } from './types';
import { config } from '../../../../config';

import { usePreClaimsItmDelete } from './usePreClaimsItmDelete';

import styles from './styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const PreClaimsItmFlags = ({ fields }: PreClaimsItmFlagsProps) => {
  const onApiErrorCallback = (e: AxiosError) => {
    // TODO: Handle API Error
    // eslint-disable-next-line no-console
    console.log(e);
  };
  const {
    showSideDrawer,
    isLoading: isStatusUpdateLoading,
    showPageModal,
    showSuccessToast,
    showErrorToast,
    clickedEle,
    isEdit,
    setIsEdit,
    setShowSuccessToast,
    onAddItemFlagButtonClick,
    setShowPageModal,
    setShowSideDrawer,
    onCancelForm,
    onSuccessSubmit,
    setShowErrorToast,
    secondaryCallback,
    onErrorSubmit,
    onSuccessToastDismiss,
    onErrorToastDismiss,
    setClickedEle,
    updateITMStatus
  } = usePreclaimsFlags();

  const {
    getSortDirectionForField,
    onSort,
    rowData,
    refresh,
    onSearch,
    onSearchReset,
    onItemsPerPageChange,
    isLoading: isInventoryLoading,
    currentPage,
    onPagerClick,
    itemRangeTo,
    itemRangeFrom,
    totalItems,
    totalPages,
    itemsPerPage,
    getTableAccessibilityText
  } = useDataGridEngine<FlagRow>({
    apiBaseUrl: config.preClaimItmApi.urls.inventory,
    defaultItemsPerPage: 20,
    onApiErrorCallback
  });
  const {
    showDeleteErrorToast,
    showDeleteModal,
    showDeleteSuccessToast,
    isLoading: isDeletionLoading,
    setShowDeleteModal,
    onDeleteClick,
    onCloseDeleteToast,
    primaryDeleteCallback,
    cancelDeleteModalCallback
  } = usePreClaimsItmDelete();

  const [editableItem, setEditableItem] = useState<FlagRow | undefined>();
  const [loadingStatus, setLoadingStatus] = useState<Spinner>({
    isLoading: false
  });

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
  const siteTypeIsInternal = useFeature(FeatureFlags.INTERNAL);
  const isReadOnlyAccessUser: boolean =
    isUserInRoles(ModuleMapping.claim, [UserRole.ReadOnly], moduleRoleMapping) &&
    siteTypeIsInternal === true;

  const pageModal = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: fields.changeStatusConfirmation?.fields?.heading?.value!,
    content: fields.changeStatusConfirmation?.fields?.content?.value!,
    isActive: false,
    onCloseCallback: () => {
      setShowPageModal(false);
    }
  };
  const deletePageModal: ModalProps = {
    type: ApplicationStates.SUCCESS,
    style: 'success',
    title: fields.deleteItmFlag?.fields.heading?.value || '',
    content: fields.deleteItmFlag?.fields.content?.value,
    isActive: false,
    onCloseCallback: () => {
      setShowDeleteModal(false);
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget?.id) {
      const clickedToggle = event.currentTarget?.id.substring(
        0,
        event.currentTarget?.id.length - 5
      );
      setClickedEle(clickedToggle);
      setShowPageModal(true);
    }
  };

  const onStatusChangeFailureCallback = () => {};

  const updateITMChangeStatus = () => {
    const updateRow: FlagRow | undefined = rowData?.find(
      (row) => row.issueToManagementRecordID === clickedEle
    );

    if (updateRow !== undefined) {
      // row is not empty
      updateITMStatus(
        {
          issueToManagementRecordID: clickedEle,
          statusCode:
            updateRow?.statusCode === IssueToManagementStatus.Approved
              ? IssueToManagementStatus.NotApproved
              : IssueToManagementStatus.Approved
        },
        refresh,
        onStatusChangeFailureCallback
      );
    }
  };

  const primaryCallback = () => {
    updateITMChangeStatus();
    setShowPageModal(false);
  };

  const findITMType = (typeCodes: number[]) => {
    if (fields) {
      const itemTypes: string[] = [];
      typeCodes?.map((itmFlag: number) => {
        const result = fields?.addEditItmFlag?.fields?.itmflagList?.[0].fields?.listItems.filter(
          (item) => item.fields?.itemValue.value?.toString() === itmFlag.toString()
        );
        if (result[0]?.fields?.itemName.value) {
          itemTypes.push(result[0].fields?.itemName.value);
        }
      });

      return itemTypes.join(', ');
    }
    return '';
  };

  const onEditClick = (id: string | number) => {
    const editableObj: FlagRow | undefined = rowData?.find(
      (item) => item.issueToManagementRecordID === id
    );
    setShowSideDrawer(true);
    setShowSuccessToast(false);
    setShowErrorToast(false);
    setIsEdit(true);
    setEditableItem(editableObj);
  };
  const rowActionMenu: MenuItemProps[] = [
    {
      name: 'editButton',
      icon: 'edit',
      displayText: fields.addEditItmFlag?.fields?.editHeading,
      onClickCallback: onEditClick,
      actionItemId: 0
    },
    {
      name: 'deleteButton',
      icon: fields.deleteIcon,
      displayText: { value: fields.deleteLabel?.value },
      onClickCallback: onDeleteClick,
      actionItemId: 0
    }
  ];

  return (
    <>
      <ContentLoadingModal
        display={loadingStatus?.isLoading}
        fields={{
          heading: { value: i18n.t(loadingStatus?.spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: {
            value: i18n.t(loadingStatus?.spinnerDescription ?? 'Globals-InProgress-Description')
          }
        }}
      />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showSuccessToast}
        title={fields.successNotifcationTitle?.value}
        content={{ value: i18n.t('Globals-Toast-Success-Item-Saved') }}
        onCloseCallback={onSuccessToastDismiss}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{ value: i18n.t('Globals-Toast-Error-Item-Not-Saved') }}
        onCloseCallback={onErrorToastDismiss}
      />
      <ToastNotification
        type={ApplicationStates.SUCCESS}
        isActive={showDeleteSuccessToast}
        title={i18n.t('Globals-Toast-Success-Title')}
        content={{ value: i18n.t('DeleteITMFlag-SuccessToast-Delete') }}
        onCloseCallback={onCloseDeleteToast}
      />
      <ToastNotification
        type={ApplicationStates.ERROR}
        isActive={showDeleteErrorToast}
        title={i18n.t('Globals-Toast-Error-Title')}
        content={{ value: i18n.t('DefaultSubmission-OtherErrors') }}
        onCloseCallback={onCloseDeleteToast}
      />
      <Modal
        type={pageModal.type}
        style={pageModal.style}
        isActive={showPageModal}
        title={pageModal.title}
        onCloseCallback={() => setShowPageModal(false)}
        onPrimaryCallback={primaryCallback}
        onSecondaryCallback={secondaryCallback}
        content={pageModal.content}
        fields={{
          saveButton: {
            value: fields.changeStatusConfirmation?.fields?.acceptButton?.value
          },
          cancelButton: { value: fields.changeStatusConfirmation?.fields?.cancelButton?.value }
        }}
        isLoading={isStatusUpdateLoading}
      />
      <PageCardContainer name="preClaimsItmFlag">
        <div
          className={`${styles.preClaimsItmFlagsCardHeader} ${
            i18n.language === LanguageShort.French ? styles.preClaimsItmFlagsCardHeaderFrench : ''
          }`.trim()}
        >
          <PageCardHeaderWithAddButton
            title={fields.title}
            addButtonText={fields.addITM}
            addButtonIsSecondary={false}
            addButtonCallback={onAddItemFlagButtonClick}
            disabled={isReadOnlyAccessUser}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchBar
            name="ItmSearchBar"
            fieldLabelAriaText={fields.searchFilterLabel?.value}
            searchButtonAriaText={fields.searchButtonAriaLabel?.value}
            clearButtonAriaText={fields.searchButtonClearAriaLabel?.value}
            placeholderText={fields.searchPlaceholderTextPrefix?.value}
            onSearch={onSearch}
            onClear={onSearchReset}
            searchFieldOptions={[
              {
                displayText: fields.tableColCmhcLoan.value || '',
                value: 'CmhcLoanAccountNumber',
                type: 'number'
              }
            ]}
            count={totalItems}
          />
        </div>
        {rowData && rowData?.length > 0 && (
          <>
            <div className={styles.dataTable}>
              <DataTable
                name="preClaimsItmFlagsDataTable"
                isLoading={isInventoryLoading}
                caption={{ value: getTableAccessibilityText() ?? '' }}
                scrollOnHorizontalOverflow
              >
                <DataTableHeader>
                  {/* TODO: Correct typo in fields title */}
                  {!isReadOnlyAccessUser && (
                    <DataTableHeaderItem
                      name="actionMenu"
                      displayText={fields.tableColCmhcLoan}
                      customStyles={styles.actionMenuHeader}
                    />
                  )}
                  <DataTableHeaderItem name="cmhcLoan" displayText={fields.tableColCmhcLoan} />
                  <DataTableHeaderItem name="itmType" displayText={fields.tableColItmType} />
                  <DataTableHeaderItem
                    sortable
                    sortDirection={getSortDirectionForField('ReferenceNumber')}
                    onSortCallback={onSort}
                    name="ReferenceNumber"
                    displayText={fields.tableColItmReference}
                  />
                  <DataTableHeaderItem name="itmStatus" displayText={fields.tableColItmStatus} />
                  <DataTableHeaderItem
                    sortDirection={getSortDirectionForField('ApprovedTimestamp')}
                    sortable
                    onSortCallback={onSort}
                    name="ApprovedTimestamp"
                    displayText={fields.tableColActiveDate}
                  />
                  <DataTableHeaderItem
                    name="cancelledDate"
                    displayText={fields.tableColCancelledDate}
                  />
                </DataTableHeader>
                <DataTableBody zebra>
                  {rowData?.map((flag: FlagRow, index: number) => (
                    <DataTableRow
                      name={`${flag.issueToManagementRecordID}-row`}
                      key={flag.issueToManagementRecordID}
                    >
                      {!isReadOnlyAccessUser && (
                        <ActionButtonCell
                          name={`rowAction${index}`}
                          rowActionItems={rowActionMenu?.map((i) => ({
                            ...i,
                            actionItemId: flag.issueToManagementRecordID,
                            name: i.name + index
                          }))}
                          rowId={flag.issueToManagementRecordID}
                        />
                      )}
                      <TextCell
                        text={flag.cmhcLoanAccountNumber?.toString(10) ?? '--'}
                        name={`${flag.issueToManagementRecordID}-cmhcLoanNumber`}
                      />
                      <TextCell
                        text={findITMType(flag.typeCodes)}
                        name={`${flag.issueToManagementRecordID}-itmType`}
                      />
                      <TextCell
                        text={flag.referenceNumber?.toString(10) ?? '--'}
                        name={`${flag.issueToManagementRecordID}-itmReference`}
                      />
                      <td>
                        <FormToggle
                          isChecked={flag.statusCode === 1}
                          onChange={handleToggleChange}
                          id={flag.issueToManagementRecordID}
                          testId={`inventoryToggleTest_${index}`}
                          dynamicText={[
                            fields.tableColStatusApproved.value || '',
                            fields.tableColCancelledDate.value || ''
                          ]}
                          isDisabled={isReadOnlyAccessUser}
                        />
                      </td>
                      <TextCell
                        text={
                          flag.approvedTimestamp
                            ? convertDateForUi(
                                flag.approvedTimestamp ?? '',
                                i18n.language as LanguageShort,
                                true
                              )
                            : ''
                        }
                        name={`${flag.issueToManagementRecordID}-activeDate`}
                      />
                      <TextCell
                        text={
                          flag.notApprovedTimestamp
                            ? convertDateForUi(
                                flag.notApprovedTimestamp ?? '',
                                i18n.language as LanguageShort,
                                true
                              )
                            : '--'
                        }
                        name={`${flag.issueToManagementRecordID}-cancelledDate`}
                      />
                    </DataTableRow>
                  ))}
                </DataTableBody>
              </DataTable>
            </div>
            <DataTableFooter
              name="itmFlagTableFooter"
              currentPage={currentPage}
              totalPages={totalPages || 0}
              itemsPerPageLabel={{
                value: i18n.t('DefaultsInventoryTable-ResultsPerPageDropdown-Title')
              }}
              itemsXofYText={`${itemRangeFrom} - ${itemRangeTo} ${i18n.t(
                'Globals-Grid-ResultsPerPageDropdown-Of'
              )}`}
              totalItems={totalItems}
              pagerClickCallback={onPagerClick}
              itemsPerPageOnChangeCallback={onItemsPerPageChange}
              itemsPerPageOptions={[
                { fields: { itemName: { value: '10' }, itemValue: { value: '10' } } },
                { fields: { itemName: { value: '20' }, itemValue: { value: '20' } } },
                { fields: { itemName: { value: '50' }, itemValue: { value: '50' } } },
                { fields: { itemName: { value: '100' }, itemValue: { value: '100' } } }
              ]}
              itemsPerPageValue={itemsPerPage.toString()}
            />
          </>
        )}
      </PageCardContainer>
      <SideDrawer isActive={showSideDrawer} handleOutsideClick={onCancelForm}>
        <AddItmFlagForm
          name={`test`}
          setIsLoadingCallback={setLoadingStatus}
          loadingStatus={loadingStatus}
          onErrorCallback={onErrorSubmit}
          onSuccessCallback={() => {
            onSuccessSubmit();
            refresh();
          }}
          onCancelCallback={onCancelForm}
          fields={fields.addEditItmFlag?.fields}
          edit={isEdit}
          editableItem={editableItem}
        />
      </SideDrawer>
      <Modal
        type={deletePageModal.type}
        style={deletePageModal.style}
        isActive={showDeleteModal}
        title={deletePageModal.title}
        onCloseCallback={() => setShowDeleteModal(false)}
        onPrimaryCallback={() => primaryDeleteCallback(refresh)}
        onSecondaryCallback={cancelDeleteModalCallback}
        content={deletePageModal.content}
        fields={{
          saveButton: {
            value: fields.deleteItmFlag?.fields.acceptButton?.value
          },
          cancelButton: { value: fields.deleteItmFlag?.fields.cancelButton?.value }
        }}
        isLoading={isDeletionLoading}
      />
    </>
  );
};

export default PreClaimsItmFlags;
