import { AxiosError } from 'axios';
import { ApiError } from 'Components/Common/Api/Common/ApiError';
import { TabItemProps } from 'Components/Navigation/Tabs';
import { SortDirection } from 'Components/Common/Api/CommonEnums';
import { LogicalOperators } from 'Foundation/Api';

export type DataGridEngineConfig = {
  apiBaseUrl: string;
  onApiErrorCallback: (e: AxiosError) => void;
  defaultItemsPerPage?: number;
  currentTab?: TabItemProps;
  captionText?: string;
  loadDataOnInitialLoad?: boolean;
  tabFilterQueryLookup?: { [tabName: string]: string | null };
  isAdmin?: boolean;
  isUserModule?: boolean;
};

export type PagerData = {
  totalRecordCount: number;
  limit: number;
  offset: number;
};

export interface InventoryResponse<T> {
  statusCode: number;
  data: T[];
  error: ApiError;
  page: PagerData;
}

export enum DataGridActionType {
  SORT_COLUMN,
  SEARCH,
  PAGE_CHANGE,
  ITEMS_PER_PAGE_CHANGE,
  INITIAL_LOAD,
  TAB_CHANGE,
  POPULATE_ROW_DATA,
  TOGGLE_SHOW_OWN_ITEMS,
  SET_CUSTOM_PARAMS
}

export type DataGridAction = {
  type: DataGridActionType;
  payload?: any;
};

export type DataGridState<T> = {
  currentTab?: TabItemProps;
  pager: {
    currentPage: number;
    itemsPerPage: number;
    rangeFrom: number;
    rangeTo: number;
    totalItems: number;
    totalPages: number;
  };
  sort: {
    fieldName?: string;
    direction: SortDirection;
  };
  search: {
    field: string;
    term?: string;
  };
  showOwnInventory: boolean;
  rowData: T[] | null;
  refreshData: boolean;
  isLoading: boolean;
  customParams?: CustomParams;
};

export enum Language {
  EN = 1,
  FR = 2
}

export type CustomParams = {
  body: CustomParam[] | string;
  grouped?: boolean;
};

export type CustomParam = {
  paramName: string;
  paramValue: string;
};

export type CustomParamsLogicalOperators = LogicalOperators.AND | LogicalOperators.OR;
