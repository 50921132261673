import { useEffect, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { addOtherAdjustmentFrontendSchema, OtherAdjustment } from '@hobt/claim-domain';
import { HbtValidationErrorCodes, HttpResponseStatusCodes } from '@hobt/constants';
import { hbtResolver } from '@hobt/schema-validator';

import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { ApiClient } from 'Foundation/Api';

import { config } from '../../../../../../config';

export const useAddOtherAdjustmentsFunctions = (
  onSuccessCallback: <T extends unknown>(data: T) => void,
  onCancelCallback: () => void,
  onErrorCallback: (typeOfError: boolean) => void
) => {
  const hookForm = useForm({
    resolver: hbtResolver(addOtherAdjustmentFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);
  const { claimData, uuid } = useHBTFormContext();

  const { toggleAddOtherAdjustments } = useContext(AssessmentCalculationsContext);

  const authContext = useAuthenticationContext();

  const { putWithAuth } = ApiClient(authContext, {
    timeout: config.claimApi.requestTimeout
  });

  const watch = hookForm.watch();

  const cancel = () => {
    hookForm.reset();
    toggleAddOtherAdjustments();
    onCancelCallback();
  };

  const save: any = async (data: OtherAdjustment) => {
    const maxIndexNumber = Math.max(
      ...claimData?.otherAdjustments?.map(
        (otherAdjustment: OtherAdjustment) => otherAdjustment.indexNumber
      )
    );
    const payload = {
      otherAdjustments: [
        ...(claimData?.otherAdjustments ?? []),
        {
          ...data,
          indexNumber: (maxIndexNumber ?? 0) + 1,
          originalClaimSequenceNumber: claimData?.sequenceNumber ?? null
        }
      ]
    };

    try {
      const response = await putWithAuth(`${config.claimApi.urls.finalClaim}/${uuid}/`, payload);
      onSuccessCallback(response?.data?.data?.[0] ?? {});
      toggleAddOtherAdjustments();
    } catch (err: any) {
      let isMaxError = false;

      if (
        err?.response?.status === HttpResponseStatusCodes.ServerError &&
        err?.response?.data?.error?.errorCode ===
          HbtValidationErrorCodes.HBT_VAL_ERR_ADJUSTMENT_CODE_MAX_OCCURRENCE.code
      ) {
        isMaxError = true;
      }

      hookForm.reset();
      toggleAddOtherAdjustments();
      onErrorCallback(isMaxError);
    }
  };
  const onSubmitErrors: any = (_errs: Record<string, Object>) => {};

  useEffect(() => {
    if (watch.code != null) {
      hookForm.register('indexNumber');
      hookForm.register('originalClaimSequenceNumber');

      hookForm.setValue('indexNumber', 0);
      hookForm.setValue('originalClaimSequenceNumber', claimData?.sequenceNumber);
    }
  }, [watch.code]);

  return {
    hookForm,
    cancel,
    save,
    onSubmitErrors
  };
};
