import { HttpResponseStatusCodes } from '@hobt/constants';

import { ApiClient, ApiClientConfig } from 'Foundation/Api';
import { AuthenticationContextType } from 'Foundation/Authentication';
import { config } from '../../../../config';
import { DraftClaimResponseData } from './types';

export const useDraftClaimClient = (
  authContext: AuthenticationContextType,
  apiClientConfig: ApiClientConfig
) => {
  const getDraftClaim = async (draftClaimID: string): Promise<DraftClaimResponseData> => {
    const requestRoute = `${config.claimApi.urls.draftClaim}/${draftClaimID}/`;

    const response = await ApiClient(authContext, apiClientConfig).getWithAuth(requestRoute);

    const { claimPayees, draftClaim, lenderName } = response?.data?.data?.[0];

    return { claimPayees, ...draftClaim, lenderName };
  };

  const deleteDraftClaim = async (draftClaimID: string): Promise<boolean> => {
    const requestRoute = `${config.claimApi.urls.draftClaim}/${draftClaimID}/`;

    const response = await ApiClient(authContext, apiClientConfig).deleteWithAuth(requestRoute);

    return response?.data?.statusCode === HttpResponseStatusCodes.OK;
  };

  return { deleteDraftClaim, getDraftClaim };
};
