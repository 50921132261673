import { useFeature } from 'flagged';
import i18n from 'i18next';
import React from 'react';
import Helmet from 'react-helmet';
import {
  Placeholder,
  useSitecoreContext,
  VisitorIdentification
} from '@sitecore-jss/sitecore-jss-react';

import { Module, UserRole } from '@hobt/constants';

import { Permission, SecureRoute, SecureRouteMappings } from 'Constants';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { ApplicationException } from 'Foundation/Error';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'Project/Website/styles/main.scss';

import { LayoutProps } from './types';
import styles from './styles.module.scss';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// SvdO, TODO: compilation issue
// @ts-ignore

const Layout: React.FC<LayoutProps> = (props) => {
  const isInternalSite = useFeature(FeatureFlags.INTERNAL);

  const sitecoreContextFactory = useSitecoreContext();
  const hbtSitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  if (hbtSitecoreContext.user != null) {
    /*
      Check if a permission set exists for the current route and get appropriate permission
      based on external vs. internal portal
    */
    const secureRoute = SecureRouteMappings?.find((routeMapping: SecureRoute) => {
      if (isInternalSite === false) {
        return (
          routeMapping.sitecoreItemExternalId?.toUpperCase() === props.route.itemId?.toUpperCase()
        );
      }
      return (
        routeMapping.sitecoreItemInternalId?.toUpperCase() === props.route.itemId?.toUpperCase()
      );
    });

    if (secureRoute !== undefined) {
      /*
        Check if all of the permissions provided in routeMapping are satisfied
      */
      const isPermittedRoute = secureRoute.permissions.every((permission: Permission) =>
        permission.modules.some((module: Module) =>
          hbtSitecoreContext.user.moduleRoleMapping?.[module]?.some((role: UserRole) =>
            permission.roles.includes(role)
          )
        )
      );

      if (isPermittedRoute === false) {
        throw new ApplicationException('Globals-Unauthorized-Action');
      }
    }
  }

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet htmlAttributes={{ lang: i18n.language || 'en' }}>
        <title>{props?.route?.fields?.pageTitle?.value || 'Page'}</title>
      </Helmet>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}

      <div className={`${styles.appLayout} ${isServer() ? 'ssr-layout' : ''}`}>
        <div role="banner">
          <Placeholder name="hbt-header" rendering={props.route} />
        </div>

        <main className={styles.mainContent}>
          <Placeholder name="hbt-main" rendering={props.route} />
        </main>

        <div role="contentinfo">
          <Placeholder name="hbt-footer" rendering={props.route} />
        </div>

        <Placeholder name="hbt-session-timeout-modal" rendering={props.route} />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Layout);
