import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormContext } from 'react-hook-form';
import { ModuleKeyword, UserRole } from '@hobt/constants';

import FormDropdown from 'Components/Inputs/FormDropdown';
import FormYesNoRadios from 'Components/Inputs/FormYesNoRadios';
import FormToggle from 'Components/Inputs/FormToggle';

import LevelAccessInternalProps from 'Feature/UserManagement/models/LevelAccessInternalProps';
import { useLevelAccessFunctions } from './useLevelAccess';
import styles from './styles.module.scss';

const LevelAccessInternal: React.FC<LevelAccessInternalProps> = ({
  fields,
  isModify,
  userData
}: LevelAccessInternalProps) => {
  const editableModules: ModuleKeyword[] = [
    ModuleKeyword.Arrears,
    ModuleKeyword.Default,
    ModuleKeyword.Claim,
    ModuleKeyword.Admin
  ];
  const { setValue, register, unregister } = useFormContext();
  const moduleLabelMap: Map<ModuleKeyword, string> = new Map([
    [ModuleKeyword.Arrears, fields.arrearsReporting?.value ?? ''],
    [ModuleKeyword.Claim, fields.claimSubmission?.value ?? ''],
    [ModuleKeyword.Default, fields.managementSubmission?.value ?? ''],
    [ModuleKeyword.Admin, fields.userIsAdmin?.value ?? '']
  ]);

  const { isReadOnlyUser, moduleActiveStates, toggleModuleActive, showProbationaryInput } =
    useLevelAccessFunctions(
      editableModules,
      isModify,
      (userData !== undefined && userData) || null
    );

  const activeState = moduleActiveStates?.find((x) => x.type === 'admin')?.activeFlag as boolean;

  useEffect(() => {
    if (activeState) {
      register(`userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`);
      setValue(
        `userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`,
        UserRole.CmhcAdministrator.toString()
      );
    } else {
      unregister(`userAccesses[0].access.${ModuleKeyword.Admin}.roleCode`);
    }
  }, [activeState]);

  return (
    <div>
      <h2 className="card-title">
        <Text field={fields.sectionTitle} />
      </h2>
      <p>
        <Text field={fields.sectionSubtitle} />
      </p>
      {editableModules.map((module) => {
        const activeState = moduleActiveStates?.find((x) => x.type === module)?.activeFlag;

        if (activeState !== undefined) {
          return (
            <div key={module.toString()}>
              <div className="row card__body-row">
                <div className="form__element form__element--2-column col-12">
                  <div>
                    <FormToggle
                      id={`${module}FormToggle`}
                      testId={`${module}FormToggle`}
                      staticText={moduleLabelMap.get(module)}
                      isChecked={activeState}
                      onChange={() => toggleModuleActive(module)}
                    />
                  </div>
                  {activeState && module !== ModuleKeyword.Admin && (
                    <div
                      className={`form__element form__element--2-column col-6 ${styles.roleCodePadding}`}
                    >
                      <FormDropdown
                        label={fields.roleCodeLabel}
                        className={styles.hideLabel}
                        name={`userAccesses[0].access.${module}.roleCode`}
                        options={fields.userTypeList.fields.listItems}
                        isDisabled={isReadOnlyUser}
                        setAsNumber={false}
                      />
                    </div>
                  )}
                </div>
              </div>
              {module === ModuleKeyword.Claim && showProbationaryInput && (
                <div className="row card__body-row">
                  <div className="form__element form__element--2-column col-6">
                    <FormYesNoRadios
                      testid="probationaryOfficerId"
                      name="userAccesses[0].access.claim.probationaryUserFlag"
                      label={fields.probationaryOfficerLabel}
                      className={styles.probationaryOffice}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default LevelAccessInternal;
