import {
  DataGridState,
  DataGridAction,
  DataGridActionType
} from 'Components/Hooks/DataGridEngine/types';
import { SortDirection } from 'Components/Common/Api/CommonEnums';

export const createDataGridEngineReducer =
  <T,>() =>
  (state: DataGridState<T>, action: DataGridAction) => {
    // Initialize new state
    const newState: DataGridState<T> = { ...state };

    // Reducer functions that are triggered when specific actions are dispatched
    const reducers = {
      [DataGridActionType.INITIAL_LOAD]: () => {
        newState.isLoading = true;
        newState.refreshData = true;
      },
      [DataGridActionType.ITEMS_PER_PAGE_CHANGE]: () => {
        newState.pager.itemsPerPage = action.payload;
        newState.pager.currentPage = 1;
        newState.isLoading = true;
        newState.refreshData = true;
      },
      [DataGridActionType.PAGE_CHANGE]: () => {
        newState.pager.currentPage = action.payload;
        newState.isLoading = true;
        newState.refreshData = true;
      },
      [DataGridActionType.SEARCH]: () => {
        newState.search.field = action.payload.field;
        newState.search.term = action.payload.term;
        newState.isLoading = true;
        newState.refreshData = true;
        newState.pager.currentPage = 1;
      },
      [DataGridActionType.SORT_COLUMN]: () => {
        newState.isLoading = true;
        newState.refreshData = true;

        let updatedDirection = SortDirection.DEFAULT;

        if (action.payload !== newState.sort.fieldName || action.payload === undefined) {
          newState.sort.fieldName = action.payload;
          updatedDirection = SortDirection.DEFAULT;
        } else if (newState.sort.direction === SortDirection.DEFAULT) {
          updatedDirection = SortDirection.ASC;
        } else if (newState.sort.direction === SortDirection.ASC) {
          updatedDirection = SortDirection.DESC;
        }
        newState.sort.direction = updatedDirection;
      },
      [DataGridActionType.TAB_CHANGE]: () => {
        newState.currentTab = action.payload;
        newState.isLoading = true;
        newState.refreshData = true;
        newState.pager.currentPage = 1;
        newState.sort = {
          direction: SortDirection.DEFAULT
        };
      },
      [DataGridActionType.POPULATE_ROW_DATA]: () => {
        const { rowData, pager } = action.payload;

        const baseOffset = state.pager.itemsPerPage * (state.pager.currentPage - 1);

        const nonZeroItemRangeTo =
          baseOffset + state.pager.itemsPerPage >= pager?.totalRecordCount
            ? pager?.totalRecordCount
            : baseOffset + state.pager.itemsPerPage;

        newState.rowData = rowData ?? [];
        newState.refreshData = false;
        newState.isLoading = false;
        newState.pager = {
          ...newState.pager,
          totalPages: Math.ceil(pager?.totalRecordCount / state.pager.itemsPerPage),
          totalItems: pager?.totalRecordCount,
          rangeFrom: pager?.totalRecordCount > 0 ? baseOffset + 1 : 0,
          rangeTo: pager?.totalRecordCount > 0 ? nonZeroItemRangeTo : 0
        };
      },
      [DataGridActionType.TOGGLE_SHOW_OWN_ITEMS]: () => {
        newState.showOwnInventory = !newState.showOwnInventory;
        newState.isLoading = true;
        newState.refreshData = true;
      },
      [DataGridActionType.SET_CUSTOM_PARAMS]: () => {
        newState.customParams = action.payload;
        newState.isLoading = true;
        newState.refreshData = true;
        newState.pager.currentPage = 1;
      }
    };

    // Execute reducer action to update the new state
    reducers[action.type]();

    // Return new state
    return newState;
  };
