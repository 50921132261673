import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';
import { useFeature } from 'flagged';
import i18n from 'i18next';

import { hbtResolver } from '@hobt/schema-validator';
import { arrearsFilesFrontendSchema, arrearsSubmissionFrontendSchema } from '@hobt/arrears-domain';
import { ReviewStatus } from '@hobt/constants';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useAuthenticationContext } from 'Foundation/Authentication';
import {
  ToastNotification,
  ToastNotificationProps
} from 'Feature/CommonComponents/ContentComponents';
import { LoaderAnimation } from 'Feature/CommonComponents/UserControls';
import { HBTFormActionsContext } from 'Feature/Claims/components/HBTFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ArrearsSubmissionListDetailsProps from 'Feature/Arrears/models/ArrearsSubmissionListDetailsProps';
import { SubmissionType } from 'Feature/Arrears/models/types';
import { ApplicationStates } from 'Feature/CommonComponents/Enums';
import ContentLoadingModal from 'Feature/PageComponents/components/ContentLoadingModal';
import { ProgressBar } from 'Components/ProgressBar';
import { Button } from 'Components/Common/Button';
import { StickyContainer } from 'Components/StickyContainer';
import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { pathNames } from 'Constants/pathNames';
import { Spinner } from 'Constants/Types/LoadingSpinnerTypes';

import {
  getFilesRequest,
  getDetailsRequest
} from '../ArrearsSubmissionForm/ArrearsSubmissionService';
import ArrearsResources from '../ArrearsResources';
import ArrearsReport from '../Cards/ArrearsReport';
import '../ArrearsSubmissionForm/ArrearsSubmissionForm.css';
import NotificationCardComponent from '../DisplayNotificationCard';
import ArrearsContactDetails from '../Cards/ArrearsContactDetails';
import ArrearsAdditionalRemarks from '../Cards/ArrearsAdditionalRemarks';
import { sitecoreComponentToFieldNameMap } from '../ArrearsSubmissionForm/types';
import { ReportingPeriodField } from 'Feature/Arrears/models/ArrearsContactDetailsProps';
import { useArrearsSubmissionFunctions } from '../ArrearsSubmissionForm/useArrearsSubmissionFunctions';
import styles from './styles.module.scss';

const arrearsLeftMappingExternal: Record<string, React.FC<any>> = {
  ArrearsContactDetails,
  ArrearsReport
};

const arrearsLeftMappingInternal: Record<string, React.FC<any>> = {
  ...arrearsLeftMappingExternal,
  ArrearsAdditionalRemarks
};

const arrearsRightMapping: Record<string, React.FC<any>> = {
  ProgressBar,
  ArrearsResources
};

const ArrearsSubmissionListDetails = (props: ArrearsSubmissionListDetailsProps) => {
  const { rightContent, leftContent } = props?.rendering?.placeholders;

  const history = useHistory();
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const {
    isCardComplete,
    addCompleteCard,
    setCompleteCards,
    removeCompleteCard,
    clearCompleteCards
  } = useArrearsSubmissionFunctions([]);

  const [filesData, setFilesData] = useState<any>();
  const [contactData, setContactData] = useState<any>();
  const [failedUploads, setFailedUploads] = useState<string[]>([]);
  const [isSubmitFailed, setSubmitFailed] = useState<boolean>(false);
  const [isArrearsToast, setArrearsToast] = useState<boolean>(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const [isSetReportData, setReportData] = useState<ReportingPeriodField[]>([]);
  const [{ isLoading, spinnerHeading, spinnerDescription }, setLoadingState] = useState<Spinner>({
    isLoading: false
  });

  const methods = useForm({
    resolver: hbtResolver(arrearsSubmissionFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const filesMethods = useForm({
    resolver: hbtResolver(arrearsFilesFrontendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  } as Record<string, any>);

  const arrearsLeftMapping =
    isInternal === true ? arrearsLeftMappingInternal : arrearsLeftMappingExternal;

  const onReset = (): void => history.push(`/${i18n.language}${pathNames.dashboard}`);

  const handleToastAction = (type: SubmissionType) => {
    setSubmitFailed(type === SubmissionType.ERROR);
    setArrearsToast(type !== SubmissionType.OTHER);
  };

  const arrearsAdditionalPropMappings: Record<string, any> = {
    ArrearsReport: {
      isModify: true,
      arrearsFiles: filesData,
      filesMethods,
      setSubmitFailed,
      setArrearsToast,
      setIsLoadingCallback: setLoadingState,
      activeReportData: isSetReportData
    },
    ArrearsContactDetails: { isModify: true, setReportData },
    ArrearsAdditionalRemarks: { isModify: true },
    ProgressBar: {
      isDisabled: true,
      cards: leftContent,
      title: rightContent[0].fields.heading,
      cardNameToValidationFieldMap: sitecoreComponentToFieldNameMap,
      primaryButtonText: rightContent[0].fields.submitButton,
      secondaryButtonText: rightContent[0].fields.saveDraftButton,
      secondaryButtonOnClickCallback: () => onReset()
    }
  };

  const toastNotificationProps: ToastNotificationProps = {
    isActive: isArrearsToast,
    title: i18n.t(
      `Default${
        isSubmitFailed ? 'Submission-ErrorToast' : 'sInventoryTable-DecisioningToastMessage-'
      }Title`
    ),
    type: ApplicationStates[isSubmitFailed ? 'ERROR' : 'SUCCESS'],
    content: i18n.t(
      isSubmitFailed ? 'DefaultSubmission-OtherErrors' : 'ArrearsFlyout-SuccessToastMessage'
    ),
    onCloseCallback: () => handleToastAction(SubmissionType.OTHER)
  };

  const getArrearsDetails = async (id: string) => {
    setIsDetailsLoading(true);
    const res: any = await getDetailsRequest(authenticationContext, id);
    setIsDetailsLoading(false);
    setContactData(res?.data?.[0]);
  };

  const getArrearsFiles = async (id: string) => {
    const res: any = await getFilesRequest(authenticationContext, id);
    setFilesData(res?.data);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(!isServer() ? window.location.search : '');

    const id = urlParams.get('id') ?? '';

    if (id.length > 0) {
      getArrearsDetails(id);
      getArrearsFiles(id);
    }

    // set all cards to completed as per business requirements
    addCompleteCard(sitecoreComponentToFieldNameMap.contactDetails);
    addCompleteCard(sitecoreComponentToFieldNameMap.additionalRemarks);
    addCompleteCard(sitecoreComponentToFieldNameMap.arrearsReport);
  }, []);

  useEffect(() => {
    if (contactData?.contact != null) {
      methods.setValue('submission.contact.name', contactData.contact.name);
      methods.setValue('submission.contact.emailID', contactData.contact.emailID);
      methods.setValue('submission.remarkText', contactData.remarkText);
      methods.setValue('submission.reportingPeriod', contactData.reportingPeriod);
      methods.setValue('submission.contact.phoneNumber', contactData.contact.phoneNumber);

      methods.setValue(
        'submission.contact.extensionPhoneNumber',
        contactData.contact.extensionPhoneNumber ?? ''
      );
      methods.setValue(
        'submission.contact.financialInstitutionCode',
        contactData.contact.financialInstitutionCode
      );
      methods.setValue(
        'submission.contact.correspondenceLanguageCode',
        contactData.contact.correspondenceLanguageCode
      );
    }
  }, [contactData]);

  useEffect(() => {
    const filesWithErrors: any = [];

    if (filesData != null) {
      filesData
        ?.filter((file: any) => file.statusCode === ReviewStatus.INGESTION_FAILED)
        ?.map((file: any) => filesWithErrors.push(file.attachment.fileName));

      setFailedUploads(filesWithErrors);
    }
  }, [filesData]);

  if (isDetailsLoading) {
    return <LoaderAnimation />;
  }

  return (
    <div>
      <ContentLoadingModal
        display={isLoading}
        fields={{
          heading: { value: i18n.t(spinnerHeading ?? 'Globals-InProgress-Heading') },
          description: { value: i18n.t(spinnerDescription ?? 'Globals-InProgress-Description') }
        }}
      />

      <div className="row mb-3">
        <div className="col-12">
          <h2>
            <Text field={props.fields.title} />
          </h2>
        </div>
      </div>

      <ToastNotification {...toastNotificationProps} />
      {isInternal === true && failedUploads.length > 0 && (
        <div className={styles.failedErrorNotification}>
          <NotificationCardComponent
            fileNames={failedUploads}
            warningIcon={props.fields.warningIcon}
          />
        </div>
      )}

      <HBTFormActionsContext.Provider
        value={{
          setUuid: () => {},
          setCompleteCards,
          isCardComplete,
          addCompleteCard,
          removeCompleteCard,
          clearCompleteCards,
          enableEditMode: () => {},
          disableEditMode: () => {}
        }}
      >
        <FormProvider {...methods}>
          <form
            id="arrearsSubmissionListDetails"
            className="form arrears-submission-form"
            noValidate
          >
            <div className={styles.layoutContainer}>
              <div>
                <Element name="arrearsResourcesMobile" className={styles.hideForDesktop}>
                  {React.createElement(ArrearsResources, {
                    fields: rightContent[1].fields
                  })}
                </Element>

                {leftContent?.map(
                  (card: any, index: number) =>
                    arrearsLeftMapping[card.componentName] != null &&
                    arrearsAdditionalPropMappings[card.componentName] != null && (
                      <Element name={card.fields.name?.value} key={card.componentName + index}>
                        {React.createElement(arrearsLeftMapping[card.componentName], {
                          ...arrearsAdditionalPropMappings[card.componentName],
                          fields: card.fields,
                          key: index
                        })}
                      </Element>
                    )
                )}
              </div>
              <aside className={styles.showForDesktopOnly}>
                <StickyContainer
                  containerId="arrears-progress-bar"
                  topOffset={25}
                  shouldFillParentWidth={true}
                >
                  {rightContent?.map(
                    (card: any, index: number) =>
                      arrearsRightMapping[card.componentName] != null &&
                      arrearsAdditionalPropMappings[card.componentName] != null && (
                        <Element name={card.componentName} key={card.componentName + index}>
                          {React.createElement(arrearsRightMapping[card.componentName], {
                            ...arrearsAdditionalPropMappings[card.componentName],
                            fields: card.fields,
                            key: index
                          })}
                        </Element>
                      )
                  )}
                </StickyContainer>
              </aside>
            </div>
            <StickyFooter>
              <div className={`${styles.stickyFooter} ${styles.hideForDesktop}`}>
                <Button
                  type="submit"
                  name="primary-button"
                  disabled={true}
                  text={arrearsAdditionalPropMappings.ProgressBar.primaryButtonText}
                />
                <Button
                  name="secondary-button"
                  type="button"
                  secondaryButton
                  text={arrearsAdditionalPropMappings.ProgressBar.secondaryButtonText}
                  onClick={() => {
                    arrearsAdditionalPropMappings.ProgressBar.secondaryButtonOnClickCallback();
                  }}
                />
              </div>
            </StickyFooter>
          </form>
        </FormProvider>
      </HBTFormActionsContext.Provider>
    </div>
  );
};

export default ArrearsSubmissionListDetails;
