import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './polyfills';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { ReactElement } from 'react';
import { Root, createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { enableDebug } from '@sitecore-jss/sitecore-jss-react';
import AppRoot from './Project/Website/components/AppRoot';
import GraphQLClientFactory from 'Foundation/GraphQL/factories/GraphQLClientFactory';
import i18ninit from 'Foundation/Translation/services/i18n';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import config from 'temp/config';
import { setServerSideRenderingState } from 'Project/Website/components/RouteHandler';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { SsrState } from 'Project/Website/components/types';

type RenderFunctionType = (
  rootElement: HTMLElement | DocumentFragment | Element,
  component: ReactElement
) => void;

if (process.env.REACT_APP_DEBUG) {
  enableDebug(process.env.REACT_APP_DEBUG);
}

/* eslint-disable no-underscore-dangle */

let renderFunction: RenderFunctionType = (rootElement, component) => {
  const root: Root = createRoot(rootElement);
  root.render(component);
};

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__: SsrState = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // push the initial SSR state into the route handler, where it will be used
  setServerSideRenderingState(__JSS_STATE__);

  // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
  renderFunction = hydrateRoot as any;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState: NormalizedCacheObject =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : Object.create(null);

const graphQLClient: ApolloClient<NormalizedCacheObject> = GraphQLClientFactory(
  config.graphQLEndpoint,
  false,
  initialGraphQLState
);

if (isServer() === false) {
  /*
  App Rendering
*/
  // initialize the dictionary, then render the app
  // note: if not making a multlingual app, the dictionary init can be removed.
  i18ninit().then(() => {
    // HTML element to place the app into
    const rootElement = document.getElementById('root');
    renderFunction(
      rootElement as HTMLElement,
      <AppRoot
        path={window.location.pathname}
        Router={BrowserRouter}
        graphQLClient={graphQLClient}
        ssrState={__JSS_STATE__}
      />
    );
  });
}
