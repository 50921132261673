import { useFormContext } from 'react-hook-form';

import {
  BorrowerCharges,
  BorrowerChargeItem,
  BorrowerChargeRow
} from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/BorrowerCharge/types';
import { groupBy } from 'Components/Common/Api/utils/GroupBy';

export const useBorrowerChargeFunctions = () => {
  const { watch } = useFormContext();

  const formData = watch('borrowerCharges');

  const getSubtotals = <T extends unknown>(
    valueArray: T[],
    fieldToSumCallback: (val: T) => number | undefined
  ) => {
    return valueArray?.map(fieldToSumCallback).reduce((acc, val) => {
      if (acc != null || val != null) {
        return (acc ?? 0) + (val ?? 0);
      }
    });
  };

  const parseBorrowerChargesData = (claimData?: {
    [label: string]: object;
  }): BorrowerCharges | never => {
    // Check if data is there
    if (claimData?.borrowerCharges !== undefined) {
      const indexedBorrowerCharges: BorrowerChargeItem[] = (
        claimData?.borrowerCharges as BorrowerChargeItem[]
      )?.map((row: BorrowerChargeItem, idx: number) => ({ ...row, idx }));

      const groupedBorrowerCharges: Map<number, Array<BorrowerChargeItem>> = groupBy<
        number,
        BorrowerChargeItem
      >(indexedBorrowerCharges ?? [], (item) => item.code ?? 0);

      // Initialize column subtotals data object
      const columnTotals: BorrowerChargeItem = {
        adjustedAmount: 0,
        acceptableAmount: 0,
        differenceAmount: 0,
        previouslyApprovedAmount: 0,
        receivedAmount: 0
      };

      const chargeRows: BorrowerChargeRow[] = [];

      groupedBorrowerCharges.forEach((items, key) => {
        const row: BorrowerChargeRow = {
          natureOfCharge: key,
          items: items?.map((item: BorrowerChargeItem) => ({
            ...item,
            adjustedAmount:
              item.idx != null && formData?.[item.idx]?.adjustedAmount != null
                ? formData[item.idx].adjustedAmount
                : 0,
            differenceAmount:
              item.idx != null &&
              item.receivedAmount != null &&
              formData?.[item.idx]?.adjustedAmount != null
                ? formData?.[item.idx]?.adjustedAmount - item.receivedAmount
                : undefined
          })),
          subtotals: {
            previouslyApprovedAmount: getSubtotals<BorrowerChargeItem>(
              items,
              (item: BorrowerChargeItem) => item.previouslyApprovedAmount
            ),
            receivedAmount: getSubtotals<BorrowerChargeItem>(
              items,
              (item: BorrowerChargeItem) => item.receivedAmount
            ),
            acceptableAmount: getSubtotals<BorrowerChargeItem>(
              items,
              (item: BorrowerChargeItem) => item.acceptableAmount
            )
          }
        };

        // Update column sub totals
        columnTotals.receivedAmount = columnTotals.receivedAmount
          ? columnTotals.receivedAmount + (row.subtotals?.receivedAmount ?? 0)
          : row.subtotals?.receivedAmount;

        columnTotals.previouslyApprovedAmount = columnTotals.previouslyApprovedAmount
          ? columnTotals.previouslyApprovedAmount + (row.subtotals?.previouslyApprovedAmount ?? 0)
          : row.subtotals?.previouslyApprovedAmount;

        columnTotals.acceptableAmount = columnTotals.acceptableAmount
          ? columnTotals.acceptableAmount + (row.subtotals?.acceptableAmount ?? 0)
          : row.subtotals?.acceptableAmount;

        chargeRows.push(row);
      });

      // Compile final data object
      return {
        chargeRows,
        subTotals: columnTotals
      };
    }

    throw new Error('could not parse final claim data to get Borrower Charges');
  };

  return { parseBorrowerChargesData };
};
