import React, { useContext } from 'react';
import i18next from 'i18next';
import { useFormContext } from 'react-hook-form';

import { ClaimTypeCode, TaxAccountBalanceType } from '@hobt/claim-domain';
import { LanguageShort } from '@hobt/constants';

import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { TextCell } from 'Components/Common/DataTable/DataTableRow/TextCell';
import FormNumber from 'Components/Inputs/FormNumber';
import FormDatepicker from 'Components/Inputs/FormDatepicker';
import { AssessmentCalculationsContext } from 'Feature/Claims/components/Details/AssessmentCalculations';
import { FormFieldCell } from 'Components/Common/DataTable/DataTableRow/FormFieldCell';
import {
  percentageFormat,
  getCurrencyFormat,
  currencyFormat
} from 'Components/Inputs/CommonFormFieldFormats';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { useTranslationHelpers } from 'Components/Hooks/TranslationHelpers';
import { renderSimpleValue } from 'Components/Common/Api/utils/EmptyValues';
import { getDifference, getDifferenceDateInDays } from 'Components/Common/Api/utils/GetDifference';
import { TrendableCell } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/TrendableCell';
import { ScrollableDataTableContainer } from 'Feature/Claims/components/Details/AssessmentCalculations/ClaimAssessment/ScrollableDataTableContainer';
import styles from './styles.module.scss';

const CONVEYANCE_CLAIM_TYPE_CODES = [
  ClaimTypeCode.AssignmentOfMortgage,
  ClaimTypeCode.DeficiencySale,
  ClaimTypeCode.TitleTransfer
];

export const PrincipalBalance: React.FC = () => {
  const { isEditing, sitecoreContent } = useContext(AssessmentCalculationsContext);
  const { claimData, isClaimStatusPostAdjudicationStatus } = useHBTFormContext();
  const { watch, getValues } = useFormContext();
  const { toCurrency, toLocaleDate } = useTranslationHelpers();

  const { assessment, detail } = claimData;
  const principalBalanceFields = sitecoreContent?.principalBalanceCardLabels?.fields;

  const formData = watch('assessment');

  // Submitted deficiency sale price should always display as a negative
  const submittedDeficiencySalePriceDisplayAmount =
    assessment?.receivedDeficiencySalePriceAmount > 0
      ? -1 * assessment?.receivedDeficiencySalePriceAmount
      : assessment?.receivedDeficiencySalePriceAmount;

  // Submitted tax account balance should display as a negative if taxAccountBalanceTypeCode is surplus
  const submittedTaxAccountBalanceDisplayAmount =
    detail.taxAccountBalanceTypeCode === TaxAccountBalanceType.Surplus &&
    assessment?.receivedTaxAccountBalanceAmount > 0
      ? -1 * assessment?.receivedTaxAccountBalanceAmount
      : assessment?.receivedTaxAccountBalanceAmount;

  // Difference calculation should be [Adjusted Value] - (-[Submitted Value]) for deficiencySalePrice.
  const deficiencySalePriceDifference = getDifference<number>(
    formData?.adjustedDeficiencySalePriceAmount,
    -1 * assessment?.receivedDeficiencySalePriceAmount
  );

  let taxAccountBalanceAmountDifference = getDifference<number>(
    formData?.adjustedTaxAccountBalanceAmount,
    assessment?.receivedTaxAccountBalanceAmount
  );

  if (detail.taxAccountBalanceTypeCode === TaxAccountBalanceType.Surplus) {
    // when tax account balance type is surplus, difference calculation should be [Adjusted Value] - (-[Submitted Value]) for tax account balance.
    taxAccountBalanceAmountDifference = getDifference<number>(
      formData?.adjustedTaxAccountBalanceAmount,
      -1 * assessment?.receivedTaxAccountBalanceAmount
    );
  }

  const shouldDisplayConveyancingDateField = CONVEYANCE_CLAIM_TYPE_CODES.includes(
    detail?.claimTypeCode
  );

  return (
    <div className={styles.content}>
      <ScrollableDataTableContainer name="principalBalance" className={''}>
        <div className={styles.fieldset}>
          <DataTable
            name="assessment"
            caption={principalBalanceFields?.captionTextPrincipalBalance ?? {}}
            addTableWrapper={false}
          >
            <DataTableHeader>
              <DataTableHeaderItem name="item" />
              <DataTableHeaderItem
                name="previous"
                displayText={principalBalanceFields?.previouslyApproved}
              />
              <DataTableHeaderItem
                name="submitted"
                displayText={principalBalanceFields?.submittedAmount}
              />
              <DataTableHeaderItem
                name="acceptable"
                displayText={principalBalanceFields?.acceptable}
              />
              <DataTableHeaderItem name="adjusted" displayText={principalBalanceFields?.adjusted} />
              <DataTableHeaderItem
                name="difference"
                displayText={principalBalanceFields?.difference}
              />
            </DataTableHeader>
            <DataTableBody zebra>
              <DataTableRow name="interestRate">
                <TextCell
                  text={principalBalanceFields?.interestRate?.value ?? ''}
                  name="interestRateTitle"
                />
                <TextCell
                  text={renderSimpleValue(
                    assessment?.previouslyApprovedDefaultInterestRatePercent,
                    null,
                    '%',
                    4
                  )}
                  name="interestRatePrevious"
                />
                <TextCell
                  text={renderSimpleValue(
                    assessment?.receivedDefaultInterestRatePercent,
                    null,
                    '%',
                    4
                  )}
                  name="interestRateSubmitted"
                />
                <TextCell
                  text={renderSimpleValue(
                    assessment?.acceptableDefaultInterestRatePercent,
                    null,
                    '%',
                    4
                  )}
                  name="interestRateAcceptable"
                />

                <FormFieldCell>
                  <TrendableCell
                    name={`interestRateAdjustedTrend`}
                    acceptable={assessment?.acceptableDefaultInterestRatePercent}
                    adjusted={formData?.adjustedDefaultInterestRatePercent}
                    received={assessment?.receivedDefaultRate}
                  />
                  <FormNumber
                    name="assessment.adjustedDefaultInterestRatePercent"
                    className={styles.tableInput}
                    label={{ value: 'interestRateAdjusted' }}
                    formatProps={percentageFormat}
                    allowNegative={false}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={renderSimpleValue(
                    getDifference<number>(
                      formData?.adjustedDefaultInterestRatePercent,
                      assessment?.receivedDefaultInterestRatePercent
                    ),
                    null,
                    '%',
                    4,
                    '--'
                  )}
                  name="interestRateDifference"
                />
              </DataTableRow>

              <DataTableRow name="defaultDate">
                <TextCell
                  text={principalBalanceFields?.defaultDate?.value ?? ''}
                  name="defaultDateTitle"
                />
                <TextCell
                  text={toLocaleDate(
                    assessment?.previouslyApprovedLastCompleteInstallmentPaidDueDate
                  )}
                  name="defaultDatePrevious"
                />
                <TextCell
                  text={toLocaleDate(assessment?.receivedLastCompleteInstallmentPaidDueDate)}
                  name="defaultDateSubmitted"
                />
                <TextCell
                  text={toLocaleDate(assessment?.acceptableLastCompleteInstallmentPaidDueDate)}
                  name="defaultDateAcceptable"
                />
                <FormFieldCell>
                  <FormDatepicker
                    name="assessment.adjustedLastCompleteInstallmentPaidDueDate"
                    className={styles.tableInput}
                    label={{ value: 'defaultDateAdjusted' }}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={renderSimpleValue(
                    getDifferenceDateInDays(
                      formData?.adjustedLastCompleteInstallmentPaidDueDate,
                      assessment?.receivedLastCompleteInstallmentPaidDueDate,
                      true
                    ),
                    null,
                    ` ${i18next.t('Globals-Suffix-Days')}`,
                    4,
                    '--'
                  )}
                  name="defaultDateDifference"
                />
              </DataTableRow>

              <DataTableRow name="osPrincipalBalance">
                <TextCell
                  text={principalBalanceFields?.principalBalance?.value ?? ''}
                  name="osPrincipalBalanceTitle"
                />
                <TextCell
                  text={toCurrency(assessment?.previouslyApprovedOutstandingPrincipalBalanceAmount)}
                  name="osPrincipalBalancePrevious"
                />
                <TextCell
                  text={toCurrency(assessment?.receivedOutstandingPrincipalBalanceAmount)}
                  name="osPrincipalBalanceSubmitted"
                />
                <TextCell
                  text={toCurrency(assessment?.acceptableOutstandingPrincipalBalanceAmount)}
                  name="osPrincipalBalanceAcceptable"
                />

                <FormFieldCell>
                  <TrendableCell
                    name={`interestRateAdjustedTrend`}
                    acceptable={assessment?.acceptableOutstandingPrincipalBalanceAmount}
                    adjusted={formData?.adjustedOutstandingPrincipalBalanceAmount}
                    received={assessment?.receivedOutstandingPrincipalBalanceAmount}
                  />
                  <FormNumber
                    name="assessment.adjustedOutstandingPrincipalBalanceAmount"
                    className={`${styles.tableInput} ${styles.adjustedTrendable}`}
                    label={{ value: 'principalBalanceAdjusted' }}
                    formatProps={getCurrencyFormat(
                      getValues(`assessment.adjustedOutstandingPrincipalBalanceAmount`),
                      { ...currencyFormat, thousandSeparator: true },
                      i18next.language as LanguageShort
                    )}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={toCurrency(
                    getDifference<number>(
                      formData?.adjustedOutstandingPrincipalBalanceAmount,
                      assessment?.receivedOutstandingPrincipalBalanceAmount
                    )
                  )}
                  name="osPrincipalBalanceDifference"
                />
              </DataTableRow>

              <DataTableRow name="taxAccountBalance">
                <TextCell
                  text={principalBalanceFields?.taxAccount?.value ?? ''}
                  name="taxAccountBalanceTitle"
                />
                <TextCell
                  text={toCurrency(assessment?.previouslyApprovedTaxAccountBalanceAmount, false)}
                  name="taxAccountBalancePrevious"
                />
                <TextCell
                  text={toCurrency(submittedTaxAccountBalanceDisplayAmount, false)}
                  name="taxAccountBalanceSubmitted"
                />
                <TextCell
                  text={toCurrency(assessment?.acceptableTaxAccountBalanceAmount, false)}
                  name="taxAccountBalanceAcceptable"
                />

                <FormFieldCell>
                  <TrendableCell
                    name="taxAccountBalanceAdjustedTrend"
                    acceptable={assessment?.acceptableTaxAccountBalanceAmount}
                    adjusted={formData?.adjustedTaxAccountBalanceAmount}
                    received={assessment?.receivedTaxAccountBalanceAmount}
                  />
                  <FormNumber
                    name="assessment.adjustedTaxAccountBalanceAmount"
                    className={`${styles.tableInput} ${styles.adjustedTrendable}`}
                    label={{ value: 'taxAccountBalanceAdjusted' }}
                    formatProps={getCurrencyFormat(
                      getValues(`assessment.adjustedTaxAccountBalanceAmount`),
                      { ...currencyFormat, thousandSeparator: true },
                      i18next.language as LanguageShort
                    )}
                    allowNegative={true}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={toCurrency(taxAccountBalanceAmountDifference)}
                  name="taxAccountBalanceDifference"
                />
              </DataTableRow>

              <DataTableRow name="conveyancingDate">
                <TextCell
                  text={
                    shouldDisplayConveyancingDateField
                      ? principalBalanceFields?.conveyancingDate?.value ?? ''
                      : principalBalanceFields?.lenderNotificationDate?.value ?? ''
                  }
                  name="conveyancingDateTitle"
                />
                <TextCell
                  text={toLocaleDate(
                    assessment?.previouslyApprovedConveyancingDateLenderNotificationDate
                  )}
                  name="conveyancingDatePrevious"
                />
                <TextCell
                  text={toLocaleDate(assessment?.receivedConveyancingDateLenderNotificationDate)}
                  name="conveyancingDateSubmitted"
                />
                <TextCell
                  text={toLocaleDate(assessment?.acceptableConveyancingDateLenderNotificationDate)}
                  name="conveyancingDateAcceptable"
                />

                <FormFieldCell>
                  <FormDatepicker
                    name="assessment.adjustedConveyancingDateLenderNotificationDate"
                    className={styles.tableInput}
                    label={{ value: 'defaultDateAdjusted' }}
                    isReadOnly={true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={renderSimpleValue(
                    getDifferenceDateInDays(
                      formData?.adjustedConveyancingDateLenderNotificationDate,
                      assessment?.receivedConveyancingDateLenderNotificationDate,
                      true
                    ),
                    null,
                    ` ${i18next.t('Globals-Suffix-Days')}`,
                    4,
                    '--'
                  )}
                  name="conveyancingDateDifference"
                />
              </DataTableRow>

              <DataTableRow name="deficiencySaleClosingDate">
                <TextCell
                  text={principalBalanceFields?.deficiencySaleClosing?.value ?? ''}
                  name="deficiencySaleClosingDateTitle"
                />
                <TextCell
                  text={toLocaleDate(assessment?.previouslyApprovedDeficiencySaleClosingDate)}
                  name="deficiencySaleClosingDatePrevious"
                />
                <TextCell
                  text={toLocaleDate(assessment?.receivedDeficiencySaleClosingDate)}
                  name="deficiencySaleClosingDateSubmitted"
                />
                <TextCell
                  text={toLocaleDate(assessment?.acceptableDeficiencySaleClosingDate)}
                  name="deficiencySaleClosingDateAcceptable"
                />

                <FormFieldCell>
                  <FormDatepicker
                    name="assessment.adjustedDeficiencySaleClosingDate"
                    className={styles.tableInput}
                    label={{ value: 'deficiencySaleClosingDateAdjusted' }}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={renderSimpleValue(
                    getDifferenceDateInDays(
                      formData?.adjustedDeficiencySaleClosingDate,
                      assessment?.receivedDeficiencySaleClosingDate,
                      true
                    ),
                    null,
                    ` ${i18next.t('Globals-Suffix-Days')}`,
                    4,
                    '--'
                  )}
                  name="deficiencySaleClosingDateDifference"
                />
              </DataTableRow>

              <DataTableRow name="deficiencySalePrice">
                <TextCell
                  text={principalBalanceFields?.deficencySalePrice?.value ?? ''}
                  name="deficiencySalePriceTitle"
                />
                <TextCell
                  text={toCurrency(assessment?.previouslyApprovedDeficiencySalePriceAmount)}
                  name="deficiencySalePricePrevious"
                />
                <TextCell
                  text={toCurrency(submittedDeficiencySalePriceDisplayAmount)}
                  name="deficiencySalePriceSubmitted"
                />
                <TextCell
                  text={toCurrency(assessment?.acceptableDeficiencySalePriceAmount)}
                  name="deficiencySalePriceAcceptable"
                />

                <FormFieldCell>
                  <TrendableCell
                    name="taxAccountBalanceAdjustedTrend"
                    acceptable={assessment?.acceptableDeficiencySalePriceAmount}
                    adjusted={formData?.adjustedDeficiencySalePriceAmount}
                    received={assessment?.receivedDeficiencySalePriceAmount}
                  />
                  <FormNumber
                    name="assessment.adjustedDeficiencySalePriceAmount"
                    className={`${styles.tableInput} ${styles.adjustedTrendable}`}
                    label={{ value: 'taxAccountBalanceAdjusted' }}
                    formatProps={getCurrencyFormat(
                      getValues(`assessment.adjustedDeficiencySalePriceAmount`),
                      { ...currencyFormat, thousandSeparator: true },
                      i18next.language as LanguageShort
                    )}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={toCurrency(deficiencySalePriceDifference)}
                  name="deficiencySalePriceDifference"
                />
              </DataTableRow>

              <DataTableRow name="deficiencySaleCommission">
                <TextCell
                  text={principalBalanceFields?.deficiencySaleCommission?.value ?? ''}
                  name="deficiencySaleCommissionTitle"
                />
                <TextCell
                  text={toCurrency(assessment?.previouslyApprovedDeficiencySaleCommissionAmount)}
                  name="deficiencySaleCommissionPrevious"
                />
                <TextCell
                  text={toCurrency(assessment?.receivedDeficiencySaleCommissionAmount)}
                  name="deficiencySaleCommissionSubmitted"
                />
                <TextCell
                  text={toCurrency(assessment?.acceptableDeficiencySaleCommissionAmount)}
                  name="deficiencySaleCommissionAcceptable"
                />

                <FormFieldCell>
                  <TrendableCell
                    name="taxAccountBalanceAdjustedTrend"
                    acceptable={assessment?.acceptableDeficiencySaleCommissionAmount}
                    adjusted={formData?.adjustedDeficiencySaleCommissionAmount}
                    received={assessment?.receivedDeficiencySaleCommissionAmount}
                  />
                  <FormNumber
                    name="assessment.adjustedDeficiencySaleCommissionAmount"
                    className={`${styles.tableInput} ${styles.adjustedTrendable}`}
                    label={{ value: 'taxAccountBalanceAdjusted' }}
                    formatProps={getCurrencyFormat(
                      getValues(`assessment.adjustedDeficiencySaleCommissionAmount`),
                      { ...currencyFormat, thousandSeparator: true },
                      i18next.language as LanguageShort
                    )}
                    isReadOnly={!isEditing || isClaimStatusPostAdjudicationStatus === true}
                    tabIndex={!isEditing ? -1 : 0}
                  />
                </FormFieldCell>

                <TextCell
                  text={toCurrency(
                    getDifference<number>(
                      formData?.adjustedDeficiencySaleCommissionAmount,
                      assessment?.receivedDeficiencySaleCommissionAmount
                    )
                  )}
                  name="deficiencySaleCommissionDifference"
                />
              </DataTableRow>
            </DataTableBody>
          </DataTable>
        </div>
      </ScrollableDataTableContainer>
    </div>
  );
};
