import React, { useState, useEffect } from 'react';
import { DataTable } from 'Components/Common/DataTable';
import { DataTableHeader } from 'Components/Common/DataTable/TableHeader';
import { DataTableHeaderItem } from 'Components/Common/DataTable/TableHeader/DataTableHeaderItem';
import UserAccessProps, {
  DropdownOptionType,
  UserAccessRecord
} from 'Feature/UserManagement/models/UserAccessProps';
import { DataTableBody } from 'Components/Common/DataTable/TableBody';
import { DataTableRow } from 'Components/Common/DataTable/DataTableRow';
import { FormCheckbox } from 'Components/Inputs/FormCheckbox';
import FormDropdown from 'Components/Inputs/FormDropdown';
import { Button } from 'Components/Common/Button';
import styles from './styles.module.scss';
import FormTextAreaNumber from 'Components/Inputs/FormTextAreaNumber';
import FormText from 'Components/Inputs/FormText';
import { useModifyExternalUserContext } from '../../ModifyExternalUser/ModifyExternalUserContext';
import CheckboxDropdown from 'Components/Inputs/CheckboxDropdown';
import _ from 'lodash-es';
import { useFormContext, useFieldArray } from 'react-hook-form';
import i18n from 'i18next';

const UserAccess = ({
  fields,
  addAccessRow = false,
  handleAddAccessRow,
  showUserActive,
  isModify = false,
  onRowDeleteUpdateUser
}: UserAccessProps) => {
  const [userAccessRow, setUserAccessRow] = useState<UserAccessRecord[]>([]);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const { register, setValue, unregister } = useFormContext();
  const { remove } = useFieldArray({
    name: 'userAccesses'
  });

  const AuthorizationScopeOptions = fields.authorizationList?.fields.listItems
    .map(
      // eslint-disable-next-line consistent-return
      (_item) => {
        if (_item.fields.itemName.value && _item?.fields?.itemValue?.value) {
          return {
            label: _item.fields.itemName.value,
            value: _item.fields.itemValue.value
          };
        }
      }
    )
    .filter(Boolean);

  const { userData } = useModifyExternalUserContext();

  useEffect(() => {
    addAccessRow && handleAddUserAccessRow();
    showUserActive === true ? setIsFieldDisabled(false) : setIsFieldDisabled(true);
  }, [addAccessRow, showUserActive]);

  useEffect(() => {
    userData && setUserAccessRow(userData);
  }, [userData, isModify]);

  const handleAddUserAccessRow = () => {
    const newUserAccessRow: UserAccessRecord = {
      allowedTransitNumbers: [],
      defaultFinancialInstitutionCode: '',
      defaultTransitNumber: '',
      financialInstitutionCode: '',
      lenderRoleCode: 1,
      statusCode: '',
      userAuthorizationScope: [],
      access: {
        admin: {
          activeFlag: false
        },
        arrears: {
          activeFlag: false
        },
        claim: {
          activeFlag: false
        },
        default: {
          activeFlag: false
        }
      },
      isNewRow: true
    };
    setUserAccessRow([...userAccessRow, newUserAccessRow]);
    handleAddAccessRow();
  };

  const onRowDelete = (id: string | undefined, index: number) => {
    if (id) {
      const updatedRows = userAccessRow.filter((row) => row.userAccessID !== id);
      setUserAccessRow(updatedRows);
      onRowDeleteUpdateUser && onRowDeleteUpdateUser(id);
    } else {
      // If id is undefined, delete based on index
      const updatedRows = userAccessRow.filter((row, i) => i !== index);
      remove(index);
      setUserAccessRow(updatedRows);
    }
  };

  const updateAdminAccess = (index: number) => {
    register(`userAccesses[${index}].access.admin`);
    setValue(`userAccesses[${index}].access.admin`, { activeFlag: false });
  };

  return (
    <div className={styles.dataTable}>
      <DataTable
        name="claimsDataTable"
        caption={{ value: '' }}
        isLoading={false}
        scrollOnHorizontalOverflow
      >
        <DataTableHeader>
          <DataTableHeaderItem
            name="allowedTransitNumbers"
            displayText={fields.allowedTransitNumbers}
          />
          <DataTableHeaderItem
            name="defaultFinancialInstitutionCode"
            displayText={fields.defaultFinancialInstitutionCode}
          />
          <DataTableHeaderItem
            name="defaultTransitNumber"
            displayText={fields.defaultTransitNumber}
          />
          <DataTableHeaderItem
            name="financialInstitutionCode"
            displayText={fields.financialInstitutionCode}
          />
          <DataTableHeaderItem name="lenderRoleCode" displayText={fields.lenderRoleCode} />
          <DataTableHeaderItem name="statusCode" displayText={fields.statusCode} />
          <DataTableHeaderItem
            name="userAuthorizationScope"
            displayText={fields.authorizationScope}
          />
          <DataTableHeaderItem name="arrears" displayText={fields.arrears} />
          <DataTableHeaderItem name="claim" displayText={fields.claim} />
          <DataTableHeaderItem name="default" displayText={fields.default} />
          <DataTableHeaderItem name="claimToolAccess" displayText={fields.claimToolAccess} />
          <DataTableHeaderItem name="action" displayText={fields.action} />
        </DataTableHeader>
        <DataTableBody zebra>
          {userAccessRow != null &&
            userAccessRow.map((row: UserAccessRecord, index: number) => (
              <DataTableRow name={`access-${row.userAccessID}`} key={`access-${row.userAccessID}`}>
                <td>
                  <FormTextAreaNumber
                    className={styles.form}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].allowedTransitNumbers`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={styles.form}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].defaultFinancialInstitutionCode`}
                    maxLength={3}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={styles.form}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].defaultTransitNumber`}
                    maxLength={5}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormText
                    className={styles.form}
                    label={{ value: '' }}
                    name={`userAccesses[${index}].financialInstitutionCode`}
                    maxLength={3}
                    isReadOnly={
                      isFieldDisabled === true || (isModify === true && row.isNewRow === undefined)
                    }
                  />
                </td>

                <td>
                  <>
                    <FormDropdown
                      label={''}
                      disableDefaultSelect={false}
                      className={styles.form}
                      name={`userAccesses[${index}].lenderRoleCode`}
                      options={fields.lenderRoleCodeList.fields.listItems}
                      isDisabled={isFieldDisabled === true}
                    />
                    {updateAdminAccess(index)}
                  </>
                </td>

                <td>
                  <FormDropdown
                    label={''}
                    disableDefaultSelect={false}
                    className={styles.form}
                    name={`userAccesses[${index}].statusCode`}
                    options={fields.statusCodeList.fields.listItems}
                    isDisabled={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <div className={styles.selectInput}>
                    <CheckboxDropdown
                      name={`userAccesses[${index}].userAuthorizationScope`}
                      options={AuthorizationScopeOptions as DropdownOptionType[]}
                      placeholder={i18n.t('Dropdown-Option-PleaseSelect')}
                      selectedValues={row.userAuthorizationScope}
                    />
                  </div>
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className=""
                    name={`userAccesses[${index}].access.arrears.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className=""
                    name={`userAccesses[${index}].access.claim.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormCheckbox
                    label={{ value: '' }}
                    className=""
                    name={`userAccesses[${index}].access.default.activeFlag`}
                    isReadOnly={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <FormDropdown
                    label={''}
                    disableDefaultSelect={false}
                    className={styles.full}
                    name={`userAccesses[${index}].access.claim.inventoryViewTypeCode`}
                    options={fields.claimToolAccessList.fields.listItems}
                    isDisabled={isFieldDisabled === true}
                  />
                </td>
                <td>
                  <Button
                    text={fields.removeBtn}
                    ariaText={fields.removeBtn}
                    name="removeAccessBtn"
                    contextualButton
                    icon={() => (
                      <span
                        className={`material-icons-outlined icon--v-align-middle ${styles.actionIcons}`}
                      >
                        delete_forever
                      </span>
                    )}
                    onClick={() => onRowDelete(row.userAccessID, index)}
                    type="button"
                    disabled={isFieldDisabled === true}
                  />
                </td>
              </DataTableRow>
            ))}
        </DataTableBody>
      </DataTable>
    </div>
  );
};

export default UserAccess;
