import * as React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { FlagsProvider } from 'flagged';
import { Route, Switch } from 'react-router-dom';

import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import ErrorBoundary from 'Foundation/Error/ErrorBoundary';
import RouteHandler from 'Project/Website/components/RouteHandler';
import componentFactory from 'temp/componentFactory';

import { AppInsightsContextProvider } from 'Foundation/Analytics/AppInsightsContext';
import { config } from '../../../config';
import { TJssProps } from './types';

// This is the main TSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path

const LANGUAGE_REG_EXP = /^\/?(([a-z]{2}-[A-Z]{2})|([a-z]{2}))(\/|$)/g;

export const parseRouteParams = (url: string | undefined) => {
  const language = url?.match(LANGUAGE_REG_EXP);
  const route = url?.replace(LANGUAGE_REG_EXP, '');
  return {
    route: route?.startsWith('/') ? route : `/${route}`,
    language: language ? language[0].replace(/\//g, '') : undefined
  };
};
const JssRoute = (props: TJssProps) => {
  const { url } = props.match;
  return (
    <RouteHandler
      {...parseRouteParams(url as string | undefined)}
      routeProps={props}
      isSSR={props.isSSR}
    />
  );
};

export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({
  path,
  Router,
  graphQLClient,
  ssrState
}: {
  path: string;
  Router: any;
  graphQLClient: ApolloClient<NormalizedCacheObject>;
  ssrState: any;
}) => {
  // @ts-ignore

  return (
    <ApolloProvider client={graphQLClient}>
      <SitecoreContext componentFactory={componentFactory} layoutData={ssrState}>
        <FlagsProvider features={[config.app.siteType || FeatureFlags.EXTERNAL]}>
          <AppInsightsContextProvider>
            <Router location={path} context={{}}>
              <ErrorBoundary>
                <Switch>
                  {routePatterns.map((routePattern) => (
                    <Route
                      key={routePattern}
                      path={routePattern}
                      render={(props) => <JssRoute {...props} isSSR={!!ssrState} />}
                    />
                  ))}
                </Switch>
              </ErrorBoundary>
            </Router>
          </AppInsightsContextProvider>
        </FlagsProvider>
      </SitecoreContext>
    </ApolloProvider>
  );
};

export default AppRoot;
